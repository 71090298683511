<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6">
              <a-form-item label="搜索条件">
                <a-select v-model="queryParam.queryType">
                  <a-select-option value="accessCode">
                    卡号
                  </a-select-option>
                  <a-select-option value="username">
                    用户名
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8">
              <a-form-item>
                <a-input v-model="queryParam.query" placeholder="请输入搜索内容"/>
              </a-form-item>
            </a-col>
            <a-col :md="6">
              <span>
                自动转全角<a-switch v-model="queryParam.toSbc"></a-switch>
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="() => this.queryParam = {queryType: 'accessCode', toSbc: true}">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => 'player' + record.id"
        :columns="columns"
        :data="loadData"
      >
        <a-descriptions
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0; max-width: 100vw"
          title="详细资料">
          <a-descriptions-item label="Access Code">{{ record.accessCode }}</a-descriptions-item>
          <a-descriptions-item label="姓名框ID">
            {{ record.plateId }}
          </a-descriptions-item>
          <a-descriptions-item label="称号ID">
            {{ record.titleId }}
          </a-descriptions-item>
          <a-descriptions-item label="伙伴ID">
            {{ record.partnerId }}
          </a-descriptions-item>
          <a-descriptions-item label="底板ID">
            {{ record.frameId }}
          </a-descriptions-item>
          <a-descriptions-item label="选择地图ID">
            {{ record.selectMapId }}
          </a-descriptions-item>
          <a-descriptions-item label="旅行伙伴">
            {{ record.charaSlot.join(',') }}
          </a-descriptions-item>
          <a-descriptions-item label="旅行伙伴锁定">
            {{ record.charaLockSlot.join(',') }}
          </a-descriptions-item>
          <a-descriptions-item label="数据">
            {{ record.contentBit }}
          </a-descriptions-item>
          <a-descriptions-item label="活动公告播放">
            {{ record.eventWatchedDate }}
          </a-descriptions-item>
          <a-descriptions-item label="最后游戏ID">
            {{ record.lastGameId }}
          </a-descriptions-item>
          <a-descriptions-item label="最后ROM版本">
            {{ record.lastRomVersion }}
          </a-descriptions-item>
          <a-descriptions-item label="最后数据版本">
            {{ record.lastDataVersion }}
          </a-descriptions-item>
          <a-descriptions-item label="最后登录时间">
            {{ record.lastLoginDate }}
          </a-descriptions-item>
          <a-descriptions-item label="最后游玩日期">
            {{ record.lastPlayDate }}
          </a-descriptions-item>
          <a-descriptions-item label="最后游玩点数">
            {{ record.lastPlayCredit }}
          </a-descriptions-item>
          <a-descriptions-item label="最后游玩模式">
            {{ record.lastPlayMode }}
          </a-descriptions-item>
          <a-descriptions-item label="最后地点ID">
            {{ record.lastPlaceId }}
          </a-descriptions-item>
          <a-descriptions-item label="最后地点名">
            {{ record.lastPlaceName }}
          </a-descriptions-item>
          <a-descriptions-item label="最后ALLNET ID">
            {{ record.lastAllNetId }}
          </a-descriptions-item>
          <a-descriptions-item label="最后地区ID">
            {{ record.lastRegionId }}
          </a-descriptions-item>
          <a-descriptions-item label="最后地区名">
            {{ record.lastRegionName }}
          </a-descriptions-item>
          <a-descriptions-item label="最后客户端ID">
            {{ record.lastClientId }}
          </a-descriptions-item>
          <a-descriptions-item label="最后地区代码">
            {{ record.lastCountryCode }}
          </a-descriptions-item>
          <a-descriptions-item label="最后使用电子支付">
            {{ record.lastSelectEMoney }}
          </a-descriptions-item>
          <a-descriptions-item label="最后选择票券">
            {{ record.lastSelectTicket }}
          </a-descriptions-item>
          <a-descriptions-item label="最后选择段位">
            {{ record.lastSelectCourse }}
          </a-descriptions-item>
          <a-descriptions-item label="最后段位数">
            {{ record.lastCountCourse }}
          </a-descriptions-item>
          <a-descriptions-item label="初次游戏ID">
            {{ record.firstGameId }}
          </a-descriptions-item>
          <a-descriptions-item label="初次ROM版本">
            {{ record.firstRomVersion }}
          </a-descriptions-item>
          <a-descriptions-item label="初次数据版本">
            {{ record.firstDataVersion }}
          </a-descriptions-item>
          <a-descriptions-item label="初次游玩日期">
            {{ record.firstPlayDate }}
          </a-descriptions-item>
          <a-descriptions-item label="兼容CM版本">
            {{ record.compatibleCmVersion }}
          </a-descriptions-item>
          <a-descriptions-item label="每日奖励日期">
            {{ record.dailyBonusDate }}
          </a-descriptions-item>
          <a-descriptions-item label="每日段位奖励日期">
            {{ record.dailyCourseBonusDate }}
          </a-descriptions-item>
        </a-descriptions>
        <span slot="idSlot" slot-scope="text, record">
          <router-link :to="`/game/mai2/player/` + record.id">{{ record.id }}</router-link>
        </span>
        <span slot="nameSlot" slot-scope="text, record">
          <router-link :to="`/game/mai2/player/` + record.id">{{ record.userName }}</router-link>
        </span>
        <span slot="accessCodeSlot" slot-scope="text, record">
          <router-link :to="{name: 'Aime List', query: {accessCode: record.accessCode }}">{{ record.accessCode }}</router-link>
        </span>
        <span slot="iconSlot" slot-scope="text, record">
          <a-avatar shape="square" :src="asset + '/mai2/icon/UI_Icon_' + zeroPad(record.iconId, 6) + '.png'" />
        </span>
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { getPlayerList } from '@/api/game/mai2'
import { ASSET_HOST, zeroPad } from '@/utils/util'

export default {
  name: 'PlayerList',
  components: {
    STable
  },
  data () {
    return {
      asset: ASSET_HOST,
      roleFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: { queryType: 'accessCode', toSbc: true },
      loadData: parameter => {
        return getPlayerList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true,
          scopedSlots: { customRender: 'idSlot' }
        },
        {
          title: '用户名',
          dataIndex: 'userName',
          sorter: true,
          scopedSlots: { customRender: 'nameSlot' }
        },
        {
          title: 'Access Code',
          dataIndex: 'accessCode',
          scopedSlots: { customRender: 'accessCodeSlot' }
        },
        {
          title: '头像',
          dataIndex: 'iconId',
          scopedSlots: { customRender: 'iconSlot' }
        },
        {
          title: '总觉醒',
          dataIndex: 'totalAwake',
          sorter: true
        },
        {
          title: '段位等级',
          dataIndex: 'gradeRating',
          sorter: true
        },
        {
          title: '歌曲Rating',
          dataIndex: 'musicRating',
          sorter: true
        },
        {
          title: '总计Rating',
          dataIndex: 'playerRating',
          sorter: true
        },
        {
          title: '最高Rating',
          dataIndex: 'highestRating',
          sorter: true
        },
        {
          title: 'Grade Rank',
          dataIndex: 'gradeRank',
          sorter: true
        },
        {
          title: 'Class Rank',
          dataIndex: 'classRank',
          sorter: true
        },
        {
          title: 'Course Rank',
          dataIndex: 'courseRank',
          sorter: true
        },
        {
          title: '游玩次数',
          dataIndex: 'playCount',
          sorter: true
        },
        {
          title: 'VS次数',
          dataIndex: 'playVsCount',
          sorter: true
        },
        {
          title: 'SYNC次数',
          dataIndex: 'playSyncCount',
          sorter: true
        },
        {
          title: '赢数',
          dataIndex: 'winCount',
          sorter: true
        },
        {
          title: '帮助册数',
          dataIndex: 'helpCount',
          sorter: true
        },
        {
          title: '连击数',
          dataIndex: 'comboCount',
          sorter: true
        },
        {
          title: '总计DX分数',
          dataIndex: 'totalDeluxscore',
          sorter: true
        },
        {
          title: '总计BAS DX分数',
          dataIndex: 'totalBasicDeluxscore',
          sorter: true
        },
        {
          title: '总计ADV DX分数',
          dataIndex: 'totalAdvancedDeluxscore',
          sorter: true
        },
        {
          title: '总计EXP DX分数',
          dataIndex: 'totalExpertDeluxscore',
          sorter: true
        },
        {
          title: '总计MAS DX分数',
          dataIndex: 'totalMasterDeluxscore',
          sorter: true
        },
        {
          title: '总计ReMAS DX分数',
          dataIndex: 'totalReMasterDeluxscore',
          sorter: true
        },
        {
          title: '总计SYNC',
          dataIndex: 'totalSync',
          sorter: true
        },
        {
          title: '总计BAS SYNC',
          dataIndex: 'totalBasicSync',
          sorter: true
        },
        {
          title: '总计ADV SYNC',
          dataIndex: 'totalAdvancedSync',
          sorter: true
        },
        {
          title: '总计EXP SYNC',
          dataIndex: 'totalExpertSync',
          sorter: true
        },
        {
          title: '总计MAS SYNC',
          dataIndex: 'totalMasterSync',
          sorter: true
        },
        {
          title: '总计ReMAS SYNC',
          dataIndex: 'totalReMasterSync',
          sorter: true
        },
        {
          title: '总计达成率',
          dataIndex: 'totalAchievement',
          sorter: true
        },
        {
          title: '总计BAS达成率',
          dataIndex: 'totalBasicAchievement',
          sorter: true
        },
        {
          title: '总计ADV达成率',
          dataIndex: 'totalAdvancedAchievement',
          sorter: true
        },
        {
          title: '总计EXP达成率',
          dataIndex: 'totalExpertAchievement',
          sorter: true
        },
        {
          title: '总计MAS达成率',
          dataIndex: 'totalMasterAchievement',
          sorter: true
        },
        {
          title: '总计ReMAS达成率',
          dataIndex: 'totalReMasterAchievement',
          sorter: true
        },
        {
          title: '注册时间',
          dataIndex: 'dateTime',
          sorter: true
        }
      ]
    }
  },
  methods: {
    zeroPad: zeroPad
  }
}
</script>

<style scoped>

</style>
